
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    color: {
      type: String,
      default: '#38a0db'
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  setup(props) {
    const theme = new Set()
    if (props.size === 'extra-small') theme.add('ld-xs')
    if (props.size === 'small') theme.add('ld-sm')
    if (props.size === 'default') theme.add('ld-md')
    if (props.size === 'large') theme.add('ld-lg')
    if (props.size === 'extra-large') theme.add('ld-xl')
    return {
      customCss: [...theme]
    }
  }
})
